<!-- System: STA
    Purpose: This compoment will display project dropdown
              with a link field if more tha one project is
              available
-->
<template>
  <v-container>
    <!-- Project Link Invite Form -->
    <v-form>
      <v-row class="d-flex justify-center">
        <p>Select a project from dropdown below to get its invitation link.</p>
      </v-row>
      <!-- Select Project From Dropdown To Get Its Link-->
      <v-row>
        <v-col cols="12" md="4">
          <v-select
            name="Select Project"
            v-model="selectedProject"
            :items="invitationProjectOptions"
            item-text="project_name"
            item-value="id"
            @change="createLink"
          >
          </v-select>
        </v-col>
        <!-- Copy Link Button -->
        <v-col cols="12" md="8">
          <div class="d-flex mt-1">
            <v-text-field v-model="invite_link" readonly id="copy-link">
            </v-text-field>
            <v-btn
              v-if="invite_link"
              class="white--text mt-5"
              color="#2758f6"
              small
              @click="copyLink()"
            >
              Copy link
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "team-members",
  props: ["csv"],
  components: {},
  data() {
    return {
      isShow: true,
      is_loader: true,
      selectedProject: "",
      invite_link: null,
      sort_projects: [],
      projects: [],
    };
  },
  computed: {
    ...mapState("custom", [
      "projects_list",
      "team_members",
      "active_team_members_list",
      "archived_team_members_list",
      "selected_company",
      "dashboard_data",
      "companies_list",
      "all_store_socket_employee",
    ]),
    projects_list_option() {
      let project_options = [];
      this.team_members_projects_list.forEach((project) => {
        project_options.push({ name: project.project_name, id: project.id });
        this.projects.push({ name: project.project_name, id: project.id });
      });
      return project_options;
    },
    selectedCompanyUserStatus() {
      const company = this.companies_list.find(
        (c) => c.id === this.$route.params.id
      );
      if (company) return company.user_status;
      else {
        this.$store.dispatch("custom/redirectToCompaniesListing");
      }
    },
    /**
     * Return the active project list
     */
    active_projects() {
      return this.projects_list.active;
    },
    /**
     * Return the customize project list
     */
    invitationProjectOptions() {
      let projects = this.projects_list.active.map((d) => {
        return {
          id: d.id,
          project_name: d.project_name,
        };
      });
      projects = projects.filter(
        (project) => project.project_name !== undefined
      );
      projects.unshift({
        id: "",
        project_name: "Select Project",
        disabled: true,
      });
      return projects;
    },
    /**
     * Return the selected company
     */
    selectedCompany() {
      return this.$store.state.custom.selected_company;
    },
  },
  mounted() {
    if (
      this.$store.state.custom.projects_list.active == [] &&
      this.$store.state.custom.projects_list.archive == []
    ) {
      return this.getProjects();
    }
  },

  methods: {
    invited_members() {
      return this.active_team_members_list.filter(
        (member) => member.is_active === null
      );
    },
    /**
     * This function is resposible for copying
     * the link
     */
    copyLink() {
      var copyText = document.getElementById("copy-link");
      copyText.type = "text";
      copyText.focus();
      copyText.select();
      document.execCommand("copy");
      this.$root.$emit("snack_bar", {
        show: true,
        message: "Link Copied ",
        snackbarColor: "green",
      });
    },
    /**
     * This function is resposible for generating
     * a link for that particular project
     * (api call)
     */
    createLink() {
      this.$store.commit("custom/toggle_loader", true);
      let data = {
        company_id: this.$route.params.id,
        project_id: this.selectedProject,
      };
      this.$store
        .dispatch("custom/createLink", data)
        .then((response) => {
          this.invite_link = response.data.invite_link;
          this.$store.commit("custom/toggle_loader", false);
        })
        .catch((error) => {
          this.$store.commit("custom/toggle_loader", false);
          this.$root.$emit("snack_bar", {
            show: true,
            message: error.data.error_msg,
            snackbarColor: "red",
          });
        });
    },
    /**
     * This function is resposible for getting
     * projects list
     */
    getProjects() {
      this.$store.dispatch("custom/getProjects", {
        company_id: this.$route.params.id,
      });
    },
    handleClose() {
      this.isHidden = true;
    },
  },
};
</script>

<style>
</style>